/* eslint-disable max-len */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationPurposes, CacheKeys, SectionNames } from '@constants';
import { LoanApplicationApi } from '@models/api/loan-application';
import { CreateAppPackage } from '@models/api/response-packages/create-app-package';
import { SupplementApplicationPackage } from '@models/api/response-packages/supplement-application-package';
import { ApplicationView } from '@models/application-view.model';
import { QualificationResult } from '@models/form/qualification-result.model';
import { CreateApplicationRequest } from '@models/request/create-application-request';
import { ListApplicationFilterRequest } from '@models/request/list-application-filter-request.model';
import { AppState } from '@services/app-state.service';
import { CacheService } from '@services/cache.service';
import { ErrorService } from '@services/error.service';
import { UserService } from '@services/user.service';
import { SaveThrottle } from '@utilities/save-throttle';
import _ from 'lodash';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, delay, finalize, map } from 'rxjs/operators';
import { LoanApiToApplicationViewAdapter } from '../adapters/api-to-form/loan-application-api-to-application-view.adapter';
import { ProfileApiToLoanOfficerAdapter } from '../adapters/api-to-form/profile-api-to-loan-officer.adapter';
import { QualificationResultApiToQualificationResultAdapter } from '../adapters/api-to-form/qualification-result-api-to-qualification-result.adapter';
import { SupplementApplicationResultsApiToCurrentApplicationFormAdapter } from '../adapters/api-to-form/supplement-application-results-api-to-current-application-form.adapter';
import { CurrentApplicationFormToSupplementApplicationRequestAdapter } from '../adapters/form-to-api/current-application-form-to-supplement-application-request.adapter';
import { BorrowerRequestFulfillmentFormToBorrowerRequestFulfillmentApiAdapter } from '../adapters/form-to-api/borrower-request-fulfillment-form-to-borrower-request-fulfillment-api.adapter';
import { CurrentApplicationForm } from '@models/form/loan-application-form.model';
import { LoanOfficer } from '@models/loan-officer.model';
import { ApplicationTrackerContext } from '../tracking/application-tracker-context';
import { LoanOfficerProfileFailedError } from '../tracking/errors/loan-officer-profile-failed.error';
import { ApiService } from './api.service';
import { LoanOfficerProfileIncompleteEvent } from '../tracking/events';
import { ListLoanApplicationPackage } from '@models/api/response-packages/list-loan-application-package';
import { Tracker } from '@nbkc/tracker-angular';
import { SupplementApplicationResultsApi } from '@models/api/supplement-application-results';
import { SupplementApplicationRequest } from '@models/request/supplement-application-request.model';
import { SubmitRequest } from '@models/request/submit-request.model';
import { BorrowerRequestFulfillment } from '@models/form/borrower-request-fulfillment.model';
import { GeneralError } from '../tracking/errors/general.error';
import { LoanOfficerSummaryApi } from '@models/api/loan-officer-summary.model';
import { Constants } from '@utilities/constants';

@Injectable({
	providedIn: 'root'
})
export class ApplicationService {
	public currentApplication: CurrentApplicationForm = new CurrentApplicationForm();
	public currentlySaving: boolean = false;
	public canTurnOff: boolean = false;
	public doneSaving: boolean;
	public creatingApp: boolean = false;
	public updatedBusinessRulesEvent: Subject<QualificationResult[]> = new Subject();
	private _qualificationResultApiToQualificationResultAdapter: QualificationResultApiToQualificationResultAdapter = new QualificationResultApiToQualificationResultAdapter();
	private _profileApiToLoanOfficerAdapter: ProfileApiToLoanOfficerAdapter = new ProfileApiToLoanOfficerAdapter();
	private _apiToLoanApplicationFormAdapter: SupplementApplicationResultsApiToCurrentApplicationFormAdapter = new SupplementApplicationResultsApiToCurrentApplicationFormAdapter();
	private _loanApiToApplicationViewAdapter: LoanApiToApplicationViewAdapter = new LoanApiToApplicationViewAdapter();
	private _loanApplicationFormToApiAdapter: CurrentApplicationFormToSupplementApplicationRequestAdapter = new CurrentApplicationFormToSupplementApplicationRequestAdapter();
	private _borrowerRequestFulfillmentFormToApiAdapter: BorrowerRequestFulfillmentFormToBorrowerRequestFulfillmentApiAdapter = new BorrowerRequestFulfillmentFormToBorrowerRequestFulfillmentApiAdapter();
	private _saveThrottle: SaveThrottle = new SaveThrottle((defaultErrorHandling) => this.saveInternal(defaultErrorHandling));

	constructor(
		private api: ApiService,
		private cacheService: CacheService,
		private errorService: ErrorService,
		private userService: UserService,
		private appState: AppState,
		private router: Router,
		private tracker: Tracker
	) { }

	public loadAllForUser(filterModel?: ListApplicationFilterRequest): Observable<ApplicationView[]> {
			return this.api.post<ListApplicationFilterRequest, ListLoanApplicationPackage>('application/ListApplications',
				filterModel || { } as ListApplicationFilterRequest).pipe(
				map((response) => {
					if (response && response.Success) {
						const bundle = response.Data;
						return _.map(bundle.LoanApplications, (app: LoanApplicationApi) => {
							return this._loanApiToApplicationViewAdapter.adapt(app);
						});
					} else {
						return [];
					}
				}),
			);
	}

	public createNewAppAndRouteToIt(purpose: string): Observable<LoanApplicationApi | null> {
		if (!this.creatingApp) {
			this.creatingApp = true;
			this.appState.notReady();
			this.appState.loading('Starting a new application');
			return this.createNewAppForCurrentUser(purpose).pipe(
				map((response) => {
					if (response) {
						const app: SupplementApplicationResultsApi = new SupplementApplicationResultsApi();
						app.ModifiedApplication = response;
						this.setApplication(this._apiToLoanApplicationFormAdapter.adapt(app));
						if (this.currentApplication.loan.purpose) {
							this.router.navigate(['/apply', response.Identifiers.ApplicationId, SectionNames.loan]);
						}
					}
					return response;
				}),
				catchError((error) => {
					this.errorService.handle({
						data: error,
						display: 'error-page',
						message: 'Cannot start a new application. Use the button below to try again.',
						retryAction: () => {
							this.createNewAppAndRouteToIt(purpose).subscribe();
						},
						retryText: 'Start New Application'
					});
					return throwError(error);
				}),
				finalize(() => {
					this.appState.ready();
					this.appState.notLoading();
					this.creatingApp = false;
				})
			);
		}
	}

	public createNewAppForCurrentUser(purpose: string): Observable<LoanApplicationApi | null> {
		const createOptions = new CreateApplicationRequest();
		createOptions.Tracking = {
			Tags: { }
		};

		createOptions.purpose = purpose; 
		const loanOfficer = this.cacheService.get(CacheKeys.lastLoanOfficer);
		if (loanOfficer) {
			createOptions.AssignedAgent = loanOfficer;
		}

		const registerToken = this.cacheService.get(CacheKeys.registerToken);
		if (registerToken) {
			createOptions.SecurityToken = registerToken;
		}

		const interactionTracking = this.cacheService.get(CacheKeys.interactionTracking);

		_.forEach(interactionTracking, ((value, key) => {
			if (!(value instanceof Array)) {
				createOptions.Tracking.Tags[key] = [value];
			} else {
				createOptions.Tracking.Tags[key] = value;
			}
		}));

		return this.api.post<CreateApplicationRequest, CreateAppPackage>('Application/CreateApplication', createOptions).pipe(
			map((response) => {
				if (response && response.Success && response.Data.IsQualifiedForCreation) {
					this.cacheService.clear(CacheKeys.lastLoanOfficer);
					this.cacheService.clear(CacheKeys.registerToken);
					this.cacheService.clear(CacheKeys.interactionTracking);
					if (response.Data.Choices?.[Constants.ChoiceListNames.LoanOfficers]) {
						const loanOfficers: LoanOfficerSummaryApi[] = [];
						for (const item of response.Data.Choices?.[Constants.ChoiceListNames.LoanOfficers]) {
							loanOfficers.push(({
								UserId: item.ItemId,
								DisplayName: item.ItemText
							}) as LoanOfficerSummaryApi);
						}
						this.appState.loanOfficers = loanOfficers;
					}
				} else if (!response.Data.IsQualifiedForCreation) {
					const failedRules = this._qualificationResultApiToQualificationResultAdapter.adaptCollectionWith(
						_.filter(response.Data.QualificationResults, (x) => !x.PassesCondition), (item, result) => {
							result.passed = false;
							return result;
						}, { compact: true });
					_.each(failedRules, (rule) => {
						if (_.includes(rule.tags, 'userProfile')) {
							this.router.navigate(['/profile'], { state: { action: 'createApp' }});
						}
					});
					return null;
				}

				return response.Data.CreatedApplication || null;
			})
		);
	}

	public getLoanOfficer(loanOfficerId: string): Observable<LoanOfficer> {
		if (loanOfficerId) {
			return this.userService.getUserById(loanOfficerId).pipe(
				map((response) => {
					if (response.Success) {
						if (
							response.Data.FirstName &&
							response.Data.LastName &&
							(response.Data.PrimaryPhone || response.Data.SecondaryPhone || response.Data.EmailAddress)
						) {
							return this._profileApiToLoanOfficerAdapter.adapt(response.Data);
						} else {
							this.tracker.event(new LoanOfficerProfileIncompleteEvent({ response }));
							return this.getDefaultLoanOfficer();
						}
					} else {
						this.tracker.error(new LoanOfficerProfileFailedError(new Error(JSON.stringify(response))));
						return this.getDefaultLoanOfficer();
					}
				}),
				catchError((error) => {
					this.tracker.error(new LoanOfficerProfileFailedError(error));
					return of(this.getDefaultLoanOfficer());
				})
			);
		} else {
			return of(this.getDefaultLoanOfficer());
		}
	}

	public getDefaultLoanOfficer(): LoanOfficer {
		const defaultLoanOfficer = new LoanOfficer();
		defaultLoanOfficer.firstName = 'nbkc';
		defaultLoanOfficer.lastName = 'bank';
		defaultLoanOfficer.loanOfficerEmail = 'apply.now@nbkc.com';
		defaultLoanOfficer.nmlsNum = '409631';

		if(this.hasLoanPurpose()){
			if(this.currentApplication.loan.purpose == ApplicationPurposes.heloc){
				defaultLoanOfficer.loanOfficerEmail = 'consumerlending@nbkc.com';
			}
		}
		return defaultLoanOfficer;
	}

	public getApplication(id: string): Observable<CurrentApplicationForm | null> {
		return this.api.get<SupplementApplicationPackage>(('application/Get/' + id)).pipe(
			map((result) => {
				if (result && result.Success) {
					const app: SupplementApplicationResultsApi = result.Data;
					if (result.Data.Choices?.[Constants.ChoiceListNames.LoanOfficers]) {
						const loanOfficers: LoanOfficerSummaryApi[] = [];
						for (const item of result.Data.Choices?.[Constants.ChoiceListNames.LoanOfficers]) {
							loanOfficers.push(({
								UserId: item.ItemId,
								DisplayName: item.ItemText
							}) as LoanOfficerSummaryApi);
						}
						this.appState.loanOfficers = loanOfficers;
					}
					return this._apiToLoanApplicationFormAdapter.adapt(app);
				} else {
					return null;
				}
			})
		);
	}

	public setApplication(currentApplicationForm: CurrentApplicationForm): CurrentApplicationForm;

	public setApplication(id: string): Observable<CurrentApplicationForm>;

	public setApplication(idOrCurrentApplicationForm: string | CurrentApplicationForm): Observable<CurrentApplicationForm> | CurrentApplicationForm {
		this.appState.loading('Loading your application');
		if (idOrCurrentApplicationForm instanceof CurrentApplicationForm) {
			this.currentApplication = idOrCurrentApplicationForm;
			this.currentApplication.originalApplication = _.cloneDeep(this.currentApplication);
			this.updatedBusinessRulesEvent.next(idOrCurrentApplicationForm.businessRules);
			this.appState.notLoading();
			this.tracker.setTrackerContext(new ApplicationTrackerContext({
				applicationId: this.currentApplication.id
			}));
			return this.currentApplication;
		} else {
			return this.getApplication(idOrCurrentApplicationForm).pipe(
				map((result: CurrentApplicationForm) => {
					if (result) {
						this.currentApplication = result;
						this.currentApplication.originalApplication = _.cloneDeep(this.currentApplication);
						this.updatedBusinessRulesEvent.next(result.businessRules);
						this.tracker.setTrackerContext(new ApplicationTrackerContext({
							applicationId: this.currentApplication.id
						}));
						return this.currentApplication;
					}
				}),
				catchError((response: any) => {
					if (response && response.status === 403) {
						this.router.navigate(['/']);
					} else {
						this.errorService.handle({
							data: response,
							display: 'error-page',
							message: 'Cannot load your application. Use the button below to try again.',
							retryAction: () => {
								this.router.navigate(['apply', idOrCurrentApplicationForm, SectionNames.loan]);
							},
							retryText: 'Load Your Application',
							redirectOnProd: 'status'
						});
					}
					return throwError(response);
				}),
				finalize(() => {
					this.appState.notLoading();
				})
			);
		}
	}

	public save(defaultErrorHandling: boolean = true): Observable<any> {
		return this._saveThrottle.requestSave(defaultErrorHandling);
	}

	public saveInternal(defaultErrorHandling: boolean = true): Observable<SupplementApplicationPackage> {
		const payload = this.supplementApplication();
		return this.api.post<SupplementApplicationRequest, SupplementApplicationPackage>('application/SupplementApplication/', payload).pipe(
			map((result: SupplementApplicationPackage) => {
				this.currentApplication.businessRules = this._qualificationResultApiToQualificationResultAdapter.adaptCollectionWith(
					_.filter(result.Data.QualificationResults, (x) => _.includes(x.Tags, 'businessRule')), (item, adaptedResult) => {
						adaptedResult.passed = item.PassesCondition;
						return adaptedResult;
					}, { compact: true });
				this.currentApplication.failedRules = this._qualificationResultApiToQualificationResultAdapter.adaptCollectionWith(
					_.filter(result.Data.QualificationResults, (x) => x.DisqualifiedForMilestone), (item, adaptedResult) => {
						adaptedResult.passed = false;
						return adaptedResult;
					}, { compact: true });
				this.currentApplication.isQualifiedForSubmission = result.Data.IsQualifiedForSubmission;
				return result;
			}),
			catchError((response) => {
				if (defaultErrorHandling) {
					this.errorService.handle({
						data: response,
						display: 'toast',
						message: 'Cannot save your application.',
						retryAction: () => {
							this.save().subscribe();
						}
					});
				}
				return throwError(response);
			}),
		);
	}

	public resetCurrentApplication(): void {
		const id = this.currentApplication.id;
		this.reset();
		this.setApplication(id).subscribe();
	}

	private supplementApplication(): SupplementApplicationRequest {
		return this._loanApplicationFormToApiAdapter.adapt(this.currentApplication);
	}

	public turnOnSavingIndicator(): void {
		this.currentlySaving = true;
		this.canTurnOff = false;
		this.doneSaving = false;
		setTimeout(() => {
			this.canTurnOff = true;
			this.turnOffSavingIndicator();
		}, 750);
	}

	public turnOffSavingIndicator(done: boolean = false): void {
		if (done) {
			this.doneSaving = done;
		}
		if (this.canTurnOff && this.doneSaving) {
			this.currentlySaving = false;
		}
	}

	public fulfillBorrowerRequest(fulfillment: BorrowerRequestFulfillment): Observable<void> {
		const fulfillmentApi = this._borrowerRequestFulfillmentFormToApiAdapter.adapt(fulfillment);
		return this.api.post('application/SatisfyBorrowerRequest', fulfillmentApi).pipe(
			catchError((error) => {
				this.tracker.error(new GeneralError(new Error(JSON.stringify({
					data: JSON.stringify(error),
					message: 'Borrower request fulfillment failed'
				}))));
				return of(null);
			})
		);
	}

	public submit(): Observable<any> {
		this.appState.loading('Submitting your application');
		return this.api.post('application/Submit', {
			Id: this.currentApplication.id
		} as SubmitRequest).pipe(finalize(() => {
			this.appState.notLoading();
		}));
	}

	public subscribeToBusinessRules(o: ((rules: QualificationResult[]) => void)): any {
		return this.updatedBusinessRulesEvent.subscribe(o);
	}

	public reset(): void {
		this.currentApplication = new CurrentApplicationForm();
		this.tracker.setTrackerContext(new ApplicationTrackerContext({
			applicationId: null
		}));
	}

	public hasLoanPurpose(): boolean {
		return !!(this.currentApplication.loan &&
			!(this.currentApplication.loan.purpose === '' ||
				this.currentApplication.loan.purpose === null ||
				this.currentApplication.loan.purpose === undefined));
	}
}
