import { CacheKeys } from '@constants';
import { Tracker } from '@nbkc/tracker-angular';
import { AuthService } from '@services/auth.service';
import { CacheService } from '@services/cache.service';
import { ConfigService } from '@services/config.service';
import { UserService } from '@services/user.service';
import { DynamicConfig } from '@utilities/configuration/dynamic-config';
import { getUrlVars } from '@utilities/url-helpers';
import { VisiblityMonitor } from '@utilities/visibility-monitor';
import _ from 'lodash';
import { ApplicationTrackerContext } from 'src/app/shared/tracking/application-tracker-context';
import { ApplicationStartEvent, ConfigurationLoadedEvent } from 'src/app/shared/tracking/events';
import { ConfigConstants } from '../../assets/config/config-constants';
import { TopLevelRoutes } from '../app-routing.module';
import { PlatformLocation } from '@angular/common';

const knownTopLevelRoutes = _.filter(_.map(TopLevelRoutes, (x) => x.path.split('/')[0]), (x) => x !== '' && x !== '**');

const getLoName = (cacheService: CacheService) => {
	const routeInQuestion = window.location.pathname.split('/')[1];
	if (routeInQuestion !== '' && !knownTopLevelRoutes.includes(routeInQuestion)) {
		cacheService.set(CacheKeys.lastLoanOfficer, routeInQuestion);
	}
};

export const startupFactory = (
	authService: AuthService,
	userService: UserService,
	configService: ConfigService,
	cacheService: CacheService,
	tracker: Tracker,
	platformLocation: PlatformLocation
): () => Promise<void> => {
	return () => new Promise((resolve, reject) => {
		tracker.event(new ApplicationStartEvent());
		_.forEach(getUrlVars(), (param, key) => {
			const object = { };
			object[key] = param;
			if (key === 'token') {
				cacheService.set(CacheKeys.registerToken, param);
			} else if (key !== 'code' && key !== 'state') {
				cacheService.set(CacheKeys.interactionTracking, object);
			}
		});
		getLoName(cacheService);
		const baseHref = platformLocation.getBaseHrefFromDOM();
		const applicationConfiguration = new DynamicConfig<ConfigConstants>(`${baseHref}assets/config/app.config.json`);
		applicationConfiguration.configurationValues$.subscribe((config) => {
			tracker.event(new ConfigurationLoadedEvent({ config }));
			configService.updateConfiguration(config).then(() => {
				tracker.start({
					consoleTrackerEnabled: config.CONSOLE_TRACKER_ENABLED || 'false',
					applicationInsightsExcludedDomains: config.APP_INSIGHTS_EXCLUDED_DOMAINS,
					applicationInsightsId: config.APP_INSIGHTS_ID,
					applicationInsightsTrackerEnabled: config.APP_INSIGHTS_TRACKER_ENABLED || 'false',
					googleAnalyticsIds: config.GOOGLE_ANALYTICS_IDS,
					googleAnalyticsTrackerEnabled: config.GOOGLE_ANALYTICS_TRACKER_ENABLED,
					googleTagManagerId: config.GOOGLE_TAG_MANAGER_ID,
					googleTagManagerTrackerEnabled: config.GOOGLE_TAG_MANAGER_TRACKER_ENABLED,
					googleTagManagerAuthToken: config.GOOGLE_TAG_MANAGER_AUTH_TOKEN,
					googleTagManagerEnvironment: config.GOOGLE_TAG_MANAGER_ENVIRONMENT
				}, new ApplicationTrackerContext());
				VisiblityMonitor.start(tracker);
				authService.start().then(() => userService.load().then(() => resolve()));
			});
		});
	});
};
