<app-app-header></app-app-header>
<div class="large-screen-container">
	<div class="divider-container">
		<div class="left-column flex-1">
				<div class="flex-grow ml-4 mt-1 main-title-container">
					<span class="product-title">Welcome to nbkc bank!</span>
					<div class="subtitle-container">
						<span class="subtitle">Let's get your new application started!</span>
					</div>
				</div>
				<div class="my-applications-container" role="main" aria-labelledby="inProgressApplicationsTitle">
					<div class="separator-container">
						<div *ngIf="inProgressApps?.length > 0" class="flex items-center text-center separator my-4 mx-0">
							<span id="inProgressApplicationsTitle" class="divider-text">In Progress Applications</span>
						</div>
					</div>
					<div  *ngIf="inProgressApps?.length > 0">
						<div *ngFor="let app of inProgressApps; index as $index"
							[ngClass]="{'no-border': $index === (inProgressApps.length - 1)}">
							<lib-card-component 
								[headerIcon]="getLoanIcon(app.ApplicationPurpose)"
								[headerTitle]="getLoanDisplayText(app)" 
								[headerSubTitle]="'Started on ' + grammarService.convertUtcToLocal(app.CreatedDt).format('MMMM D, YYYY')">
								<lib-button card-footer-button class="material-button-no-padding"
									[id]="'continueApplicationButton{{$index}}'" [displayText]="'Continue your application'"
									(click)="viewApp(app)">
								</lib-button>
							</lib-card-component>
						</div>
					</div>
					<div class="separator-container">
						<div *ngIf="submittedApps.length > 0" class="flex items-center text-center separator my-4 mx-0">
							<span id="submittedApplicationsTitle" class="divider-text">Submitted Applications</span>
						</div>
					</div>
					<div *ngIf="submittedApps.length > 0">
						<div *ngFor="let app of submittedApps; index as $index"
							[ngClass]="{'no-border': $index === (submittedApps.length - 1)}">
							<lib-card-component 
								[headerIcon]="getLoanIcon(app.ApplicationPurpose)"
								[headerTitle]="getLoanDisplayText(app)" 
								[headerSubTitle]="'Started on ' + grammarService.convertUtcToLocal(app.CreatedDt).format('MMMM D, YYYY')"
								[headerSecondSubTitle]="'Submitted on ' + grammarService.convertUtcToLocal(app.SubmittedDt).format('MMMM D, YYYY')">
								<lib-button card-footer-button class="material-button-no-padding"
									[id]="'submittedApplicationButton{{$index}}'" [displayText]="'Upload documents'"
									(click)="viewSubmittedApp(app)">
								</lib-button>
							</lib-card-component>
						</div>
					</div>	
					<div class="nbkc-card-section">
						<div class="separator-container">
							<div class="flex items-center text-center separator my-4 mx-0">
								<span id="newApplicationsTitle" class="divider-text">Start a new Application</span>
							</div>
						</div>
						<lib-card-component [headerIcon]="purchaseIcon"
							[headerTitle]="'Start a new Purchase Application'" [contentBody]="'You are purchasing a new home.'">
							<lib-button card-footer-button class="material-button-no-padding"
								[id]="'lib-right-action'" [displayText]="'Start an Application'"
								(click)="startCreateNewApplicationFlow('Purchase')">
							</lib-button>
						</lib-card-component>
						<lib-card-component 
							[headerIcon]="refinanceIcon"
							[headerTitle]="'Start a new Refinance Application'" 
							[contentBody]="'You are refinancing on an existing home to get a lower rate and/or to take equity out of your home.'">
							<lib-button card-footer-button class="material-button-no-padding"
								[id]="'lib-right-action'" [displayText]="'Start an Application'"
								(click)="startCreateNewApplicationFlow('Refinance')">
							</lib-button>
						</lib-card-component>
						<lib-card-component 
							*ngIf="isHelocEnabled"
							[headerIcon]="helocIcon"
							[headerTitle]="'Start a new HELOC Application'" 
							[contentBody]="'You want to tap into your existing home equity to make improvements, consolidate debt, or make a purchase.'">
							<lib-button card-footer-button class="material-button-no-padding"
								[id]="'lib-right-action'" [displayText]="'Start an Application'"
								(click)="startCreateNewApplicationFlow('HELOC')">
							</lib-button>
						</lib-card-component>
					</div>
				</div>
		</div>
		<div class = "right-column flex-intial">
			<img class="divider-image sticky-position " src="assets/img/landing-page-welcome-mat.jpg">
		</div>
	</div>
</div>


